import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError, BehaviorSubject} from 'rxjs';
import {catchError, filter, take, switchMap, tap} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {Crypt} from './utils/crypt';
import {environment} from '../../environments/environment';
import {AlertController, Platform} from '@ionic/angular';
import {AuthenticationService} from './authentication.service';
import {Router} from '@angular/router';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    isRefreshing = false;
    refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private cookies: CookieService,
                private crypt: Crypt,
                private http: HttpClient,
                private alertController: AlertController,
                private router: Router,
                private authService: AuthenticationService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.cookies.get('rma-access-token')) {
            request = this.addToken(request, this.cookies.get('rma-token'));
        }
        // if (this.storageService.get('rma-access-token')) {
        //     request = this.addToken(request, this.storageService.get('rma-token'));
        // }
        // @ts-ignore
        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401 && !request.url.includes('/api/login_check')) {
                if (request.url.includes('api/token/refresh')) {
                    return this.handle401refresh();
                } else {
                    return this.handle401Error(request, next);
                }

            } else {
                return throwError(error);
            }
        }));
    }

    private addToken(request: HttpRequest<any>, token) {
        return request.clone({
            setHeaders: {
                Authorization: `BEARER ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        this.isRefreshing = true;
        this.authService.setAuthState(false);
        //  this.authService.setRefreshState(true);
        this.refreshTokenSubject.next(null);
        const body = {
            refresh_token: this.crypt.decrypt(this.cookies.get('rma-refresh-token')),
            companyName: localStorage.getItem("companyName")

            // refresh_token: this.crypt.decrypt(this.storageService.get('rma-refresh-token'))
        };
        return this.http.post<any>(environment.apiUrl + 'api/token/refresh', body).pipe(tap((token) => {
            this.cookies.set('rma-token', token.token);
            // this.storageService.set('rma-token', token.token);
        })).pipe(
            switchMap((token: any) => {
                this.isRefreshing = false;
                this.authService.setAuthState(true);
                this.refreshTokenSubject.next(token.token);
                return next.handle(this.addToken(request, token.token));
            }));

    }

    checkLogin() {
        this.isRefreshing = true;
        this.authService.setRefreshState(true);
        this.refreshTokenSubject.next(null);
        const body = {
            refresh_token: this.crypt.decrypt(this.cookies.get('rma-refresh-token')),
            companyName: localStorage.getItem("companyName")
        };

        return this.http.post<any>(environment.apiUrl + 'api/token/refresh', body).subscribe(token => {
            this.cookies.set('rma-token', token.token);
            this.isRefreshing = false;
            this.authService.setAuthState(true);
            this.authService.setRefreshState(false);
        }, err => {
            this.isRefreshing = false;
            this.authService.setAuthState(false);
            this.authService.setRefreshState(false);

        });

    }

    private handle401refresh() {
        return new Promise((resolve, reject) => {
            this.authService.logout();
            resolve('logout');
        }).then(r => this.router.navigate(['login']));
    }

}

import {
    API_URL_DEV,
    API_URL_DEV_CLEAN,
    DEMO_APP_HIGHLIGHT_COLOUR,
    DEMO_APP_SUB_TITLE,
    DEMO_APP_TITLE,
    DEMO_APPLETOUCH_PATH,
    DEMO_FAVICON_PATH,
    DEMO_LOGO_PATH,
    TYPE_DEMO,
} from '../app/_services/utils/constants';

export const environment = {
    production: false,
    typeEnv: TYPE_DEMO,
    apiUrl: API_URL_DEV,
    apiUrlClean: API_URL_DEV_CLEAN,
    logo: DEMO_LOGO_PATH,
    favicon: DEMO_FAVICON_PATH,
    appIcon: DEMO_APPLETOUCH_PATH,
    title: DEMO_APP_TITLE,
    subtitle: DEMO_APP_SUB_TITLE,
    highlightColourHEX: DEMO_APP_HIGHLIGHT_COLOUR
};

export const CREATE_ID = 'create';
export const POPUP_COMMAND_DELETE = 'Delete';
export const POPUP_COMMAND_EDIT = 'edit';
export const API_DELETE = 'delete';
export const API_EDIT = 'edit';
export const API_ADD = 'add';
export const API_INIT = 'initial';

// API URLS
export const API_URL_STAGING = 'https://api-staging.my-ci.app/';
export const API_URL_STAGING_CLEAN = 'https://api-staging.my-ci.app';
// production environment
export const API_URL_PRODUCTION = 'https://api.my-ci.app/';
export const API_URL_PRODUCTION_CLEAN = 'https://api.my-ci.app';
// dev environment
// @ts-ignore
export const API_URL_DEV = 'https://api-dev.my-ci.app/';
// @ts-ignore
export const API_URL_DEV_CLEAN = 'https://api-dev.my-ci.app';

export const TYPE_DEMO = 'DEMOGENEO';
export const GENEO_DARK_LOGO = './assets/icons/geneo/GENEO-icon-512-no-margin.png';
export const DEMO_LOGO_PATH = './assets/icons/geneo/GENEO_Logo_white_512.png';
export const DEMO_FAVICON_PATH = 'assets/icon/geneo-favicon-64.jpg';
export const DEMO_APPLETOUCH_PATH = 'assets/icon/geneo-icon-192.jpg';
export const DEMO_APP_TITLE = 'Geneo - Continuous Improvement';
export const DEMO_APP_SUB_TITLE = 'Geneo - Continuous Improvement';
export const DEMO_APP_HIGHLIGHT_COLOUR = '#028484';


export const ANALYTICS_WORKPLACE_COACHING = 'workplace-coaching';
export const ANALYTICS_WORK_AREA_LOCATION = 'work-area-location';
